<template>
  <section class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Obrázky - Přidat</strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <a-form :form="form" @submit="handleSubmit">

        <a-form-item
          :required="true"
        >
          <a-upload-dragger
            :fileList="this.item"
            name="file"
            :multiple="true"
            :beforeUpload="beforeUpload"
            accept="image/*"
            listType="picture"
            :supportServerRender="true"
            :showUploadList="false"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox"/>
            </p>
            <p class="ant-upload-text">Chcete-li nahrát soubor, klikněte nebo přetáhněte soubor do této oblasti</p>
          </a-upload-dragger>
          <div class="ant-upload-list ant-upload-list-picture my-upload-list" v-if="item.length > 0">
            <a-row type="flex" justify="start" class="mt-5 mb-3 my-upload-list-actions">
              <a-col :span="4" class="mr-2">
                <a-select
                  @change="handleChangeAllCategory"
                  showSearch
                  placeholder="Hromadně nastavit kategorie"
                  :options="categories.map(x => { return { value: x.icy_id, label: x.icy_name }})"
                  optionFilterProp="children"
                  :filterOption="filterOption"
                  :loading="!loaded.includes('icy')"
                />
              </a-col>
            </a-row>
            <div :key="index" v-for="(item, index) in this.item" class="ant-upload-list-item">
              <div class="ant-upload-list-item-info">
                <span>
                  <a-row>
                    <a-col :md="{span:8}">
                      <a-icon type="picture" class="ant-upload-list-item-thumbnail"></a-icon>
                      <span :title="item.name" class="ant-upload-list-item-name">{{ item.name }}</span>
                    </a-col>
                    <a-col :md="{span:8}">
                      <a-form-item
                        label=""
                        :validate-status="error('icy_id') ? 'error' : ''"
                        :help="error('icy_id') || ''"
                      >
                        <a-select
                          style="max-width: 200px;"
                          v-decorator="[`icy_id[${index}]`, {rules: [{required: true, message: 'Kategorie musí být vyplněny!'}]}]"
                          showSearch
                          mode="multiple"
                          placeholder="-- Vyber kategorie --"
                          :options="categories.map(x => { return { value: x.icy_id, label: x.icy_name }})"
                          optionFilterProp="children"
                          :filterOption="filterOption"
                          :loading="!loaded.includes('icy')"
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>
                  <a-alert type="error" v-if="getError(index)" :message="getError(index).message"></a-alert>
                </span>
              </div>
              <a-icon class="anticon anticon-close" type="close" @click="handleRemove(index)" />
            </div>
          </div>
        </a-form-item>

        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="item.length === 0 || loading || hasErrors(form.getFieldsError())">Přidat</a-button>
        </div>
      </a-form>
    </div>
  </section>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../services/forms'

export default {
  components: {
    ActionTools,
  },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  data() {
    return {
      hasErrors,
      loading: false,
      errors: [],
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/image',
          title: 'Seznam',
        },
      ],
      item: [],
      loaded: [],
    }
  },
  computed: {
    categories: function () {
      return this.$store.getters['imageCategory/filteredItems']('')
    },
  },
  methods: {
    getError(index) {
      if (Array.isArray(this.errors)) {
        return this.errors.find(x => x.index === index)
      }
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
    },
    handleChangeAllCategory(id) {
      this.form.getFieldValue('icy_id').forEach((value, key) => {
        let val = value
        if (val === undefined || !Array.isArray(value)) {
          val = [id]
        } else if (!val.includes(id)) {
          val.push(id)
        }

        this.form.setFieldsValue({
          ['icy_id[' + key + ']']: val,
        })
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.errors = []
          this.loading = true
          this.$store.dispatch('image/post', this.getData())
            .then((response) => {
              if (response && response.status && response.status === 'error') {
                this.errors = response.errors
              }
            })
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getData() {
      const data = new FormData()
      this.item.forEach((value, key) => {
        data.append('images[' + key + ']', value)
      })
      data.append('json', JSON.stringify({ image_category: this.form.getFieldValue('icy_id').map(x => { return { icy_ids: x } }) }))
      return data
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    beforeUpload(file) {
      if (file.type.substring(0, file.type.indexOf('/')) === 'image') {
        const newIndex = this.item.length
        this.item.push(file)
        this.form.getFieldDecorator('icy_id[' + newIndex + ']')
        this.form.setFields({
          ['icy_id[' + newIndex + ']']: { value: undefined, errors: ['Kategorie musí být vyplněny!'] },
        })
      }
      return false
    },
    handleRemove(index) {
      this.item.splice(index, 1)
    },
    initData() {
      this.$store.dispatch('imageCategory/getList')
        .catch(() => {})
        .finally(() => {
          this.loaded.push('icy')
        })
    },
  },
  created() {
    this.initData()
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
